import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import Choose from '../components/HomePage/Choose/Choose';
import Range from '../components/HomePage/Range/Range';
import TextImage from '../components/HomePage/TextImage/TextImage';
import AccTier from '../components/HomePage/AccTier/AccTier';
import Get from '../components/HomePage/Get/Get';
import Steps from '../components/GlodalComponents/Steps/Steps';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>FMILX</title>
        <meta name="title" content="Discover boundless financial opportunities worldwide with FMILX" />
        <meta property="og:title" content="Discover boundless financial opportunities worldwide with FMILX" />
        <meta property="twitter:title" content="Discover boundless financial opportunities worldwide with FMILX" />

        <meta name="description" content="Step into the future of investing with FMILX, a premier fund providing access to a diverse range of global financial opportunities." />
        <meta property="og:description" content="Step into the future of investing with FMILX, a premier fund providing access to a diverse range of global financial opportunities." />
        <meta property="twitter:description" content="Step into the future of investing with FMILX, a premier fund providing access to a diverse range of global financial opportunities." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='main-page'>
        <Header />
        <Banner />
        <Choose />
        <Range />
        <TextImage />
        <AccTier />
        <Get />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
