import React from "react";
import styles from './Platform.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import icon from '../../../assets/images/check.svg';

const Platform = () => {
  const { t } = useTranslation();
  const list = t('fees.second.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} ${styles.colored}`}><Trans>{t('fees.second.title')}</Trans></div>
          <div className={styles.text}><Trans>{t('fees.second.text1')}</Trans></div>
          <div className={styles.subtitle}><Trans>{t('fees.second.listTitle')}</Trans></div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <img src={icon} alt="" className={styles.listItemIcon}  />
                <div className={styles.listItemTitle}>{item.title}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.text}><Trans>{t('fees.second.text2')}</Trans></div>
          <div className="mt-btn"><Button bold /></div>
        </div>
      </div>
    </section>
  );
};

export default Platform;