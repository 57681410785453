import React from "react";
import styles from './TextImage.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image1 from '../../../assets/images/homepage/image-1.webp';
import image2 from '../../../assets/images/homepage/image-2.webp';
import image3 from '../../../assets/images/homepage/image-3.webp';

const TextImage = () => {
  const { t } = useTranslation();
  const images = [image1, image2, image3];
  const list = t('main.four.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container}`}>
        {list.map((item, index) => (
          <div className={styles.row} key={index}>
            <Fade left={index % 2 !== 0} right={index % 2 === 0} duration={500} delay={index * 1000 + 100} key={index}>
              <div className={`${styles.rowItemImage} ${styles.rowItem}`}>
                <img src={images[index]} alt="" />
              </div>
            </Fade>

            <div className={`${styles.rowItemContent}  ${styles.rowItem}`}>
              <div className={styles.rowItemTitle}>{item.title}</div>
              <div className={styles.rowItemText}><Trans>{item.text}</Trans></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TextImage;