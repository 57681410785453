import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Reveal } from "react-reveal";

import image1 from '../../../assets/images/acctypes/image1.webp';
import image2 from '../../../assets/images/acctypes/image2.webp';
import image3 from '../../../assets/images/acctypes/image3.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <h1 className={`${styles.title} ${styles.colored}`}><Trans>{t('acct.first.title')}</Trans></h1>
          <div className={styles.title}><Trans>{t('acct.first.title1')}</Trans></div>
          <div className={styles.text}><Trans>{t('acct.first.text1')}</Trans></div>
          <div className={styles.text}><Trans>{t('acct.first.text2')}</Trans></div>
          <div className="mt-btn">
            <Button bold />
          </div>
        </div>
        <Reveal effect="zoomIn">
          <div className={`${styles.item} ${styles.column}`}>
            <div className={styles.itemOne}>
              <img src={image1} alt="" />
            </div>
            <div className={styles.itemTwo}>
              <img src={image2} alt="" />
              <img src={image3} alt="" />
            </div>
          </div>
        </Reveal>
      </div>
    </section>
  );
};

export default Banner;