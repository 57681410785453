import React from "react";
import styles from './Steps.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Steps = () => {
  const { t } = useTranslation();
  const list = t('main.seven.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.title}><Trans>{t('main.seven.title')}</Trans></div>
        <div className={styles.list}>
          {list.map((item, index) => (
            <div className={styles.listItem} key={index}>
              <div className={styles.listItemIndex}>0{index + 1}</div>
              <div className={styles.listItemTitle}>{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Steps;