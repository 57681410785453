import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import styles from './Tabs.module.scss';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const tabContent = t('fees.third.tabs.titles', { returnObjects: true });
  const list1 = t('fees.third.tabs.list1', { returnObjects: true });
  const list2 = t('fees.third.tabs.list2', { returnObjects: true });
  const list3 = t('fees.third.tabs.list3', { returnObjects: true });
  const list4 = t('fees.third.tabs.list4', { returnObjects: true });
  const list5 = t('fees.third.tabs.list5', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.tabButtons}>
          {tabContent.map((tab, index) => (
            <Fade left delay={index * 100 + 1} key={index}>
              <button
                key={index}
                className={index === activeTab ? styles.tabActive : ''}
                onClick={() => handleTabClick(index)}
              >
                {t(`${tab.title}`)}
              </button>
            </Fade>
          ))}
        </div>

        <div className={styles.tabsContent}>
          <div className={styles.tabTitle}>{t('fees.third.title')}</div>
          <div className={styles.tabText}><Trans>{t('fees.third.text')}</Trans></div>

          <div className={`${styles.tabItem} ${0 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('fees.third.tabs.list1-title')}</div>
            <div className={styles.tabList}>
              {list1.map((item, index) => (
                <div className={styles.tabListItem} key={index}>
                  <Trans>{item.title}</Trans>
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.tabItem} ${1 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('fees.third.tabs.list2-title')}</div>
            <div className={styles.tabList}>
              {list2.map((item, index) => (
                <div className={styles.tabListItem} key={index}>
                  <Trans>{item.title}</Trans>
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.tabItem} ${2 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('fees.third.tabs.list3-title')}</div>
            <div className={styles.tabList}>
              {list3.map((item, index) => (
                <div className={styles.tabListItem} key={index}>
                  <Trans>{item.title}</Trans>
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.tabItem} ${3 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('fees.third.tabs.list4-title')}</div>
            <div className={styles.tabList}>
              {list4.map((item, index) => (
                <div className={styles.tabListItem} key={index}>
                  <Trans>{item.title}</Trans>
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.tabItem} ${4 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('fees.third.tabs.list5-title')}</div>
            <div className={styles.tabList}>
              {list5.map((item, index) => (
                <div className={styles.tabListItem} key={index}>
                  <Trans>{item.title}</Trans>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Tabs;