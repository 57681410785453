import React from "react";
import styles from './UsetoTrade.module.scss';
import { Trans, useTranslation } from "react-i18next";

import image from '../../../assets/images/trading/image2.webp';

const UsetoTrade = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <div className={styles.title}><Trans>{t('trading.third.title')}</Trans></div>
          <div className={styles.text}><Trans>{t('trading.third.text')}</Trans></div>
          <div className={styles.text}><Trans>{t('trading.third.text1')}</Trans></div>
          <div className={styles.grid}>
            <div className={`${styles.gridItem} ${styles.gridItemWhite}`}>
              <div className={styles.gridItemTitle}>{t('trading.third.list.title1')}</div>
              <div className={styles.gridItemText}>{t('trading.third.list.text1')}</div>
            </div>
            <div className={`${styles.gridItem} ${styles.gridItemOrange}`}>
              <div className={styles.gridItemTitle}>{t('trading.third.list.title2')}</div>
              <div className={styles.gridItemText}>{t('trading.third.list.text2')}</div>
            </div>
          </div>
        </div>
        <div className={`${styles.gridItem} ${styles.right}`}>
          <div className={styles.gridItemTitle}>{t('trading.third.list.title3')}</div>
          <div className={styles.gridItemText}><Trans>{t('trading.third.list.text3')}</Trans></div>
          <div className={styles.imgWrap}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UsetoTrade;