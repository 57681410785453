import React from "react";
import styles from './Banner2.module.scss';
import { Trans, useTranslation } from "react-i18next";

import image from '../../../assets/images/trading/image3.webp';
import imagemob from '../../../assets/images/trading/mob.webp';

const Banner2 = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container}`}>
        <div className={`${styles.item} ${styles.itemFirst}`}>
          <div className={styles.subtitle}><Trans>{t('trading.four.title1')}</Trans></div>
          <div className={styles.subtitle}><Trans>{t('trading.four.title2')}</Trans></div>
          {/* <div className={styles.title}><Trans>{t('trading.four.title3')}</Trans></div> */}
        </div>
        <div className={`${styles.item} ${styles.imgWrap}`}>
          <img src={image} alt="" className="desk_only" />
          <img src={imagemob} alt="" className="mob_only" />
        </div>
      </div>
    </section>
  );
};

export default Banner2;