import React, { useEffect, useState } from "react";
import styles from './AccTier.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const AccTier = () => {
  const { t } = useTranslation();
  const list = t('main.five.list', { returnObjects: true });
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div className={styles.item}>
            <div className={`${styles.title} ${styles.colored}`}><Trans>{t('main.five.title1')}</Trans></div>
            <div className={styles.title}><Trans>{t('main.five.title2')}</Trans></div>
          </div>

          <div className={styles.item}>
            <div className={styles.text}><Trans>{t('main.five.text')}</Trans></div>
          </div>
        </div>
        {isMobile ? (
          <Marquee className={styles.mart} autoFill>
              {list.map((item, index) => (
                <div className={`${styles.listItem} ${styles[`block-${index}`]}`} key={index}>{item.title}</div>
              ))}
          </Marquee>
          
        ) : (
        <div className={styles.list}>
          {list.map((item, index) => (
            <div className={`${styles.listItem} ${styles[`block-${index}`]}`} key={index}>{item.title}</div>
          ))}
        </div>
        )}
      </div>
    </section>
  );
};

export default AccTier;