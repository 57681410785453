import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/AboutPage/Banner/Banner';
import Tabs from '../components/AboutPage/Tabs/Tabs';
// import Slider from '../components/AboutPage/Slider/Slider';

function AboutPage() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>FMILX · About us</title>
        <meta name="title" content="Revealing the vision of FMILX: empowering investors worldwide" />
        <meta property="og:title" content="Revealing the vision of FMILX: empowering investors worldwide" />
        <meta property="twitter:title" content="Revealing the vision of FMILX: empowering investors worldwide" />

        <meta name="description" content="Visit the FMILX About Us page to discover our mission of empowering investors across the globe. Explore our journey, achievements, and dedication to innovative, long-term investment solutions for a brighter financial future." />
        <meta property="og:description" content="Visit the FMILX About Us page to discover our mission of empowering investors across the globe. Explore our journey, achievements, and dedication to innovative, long-term investment solutions for a brighter financial future." />
        <meta property="twitter:description" content="Visit the FMILX About Us page to discover our mission of empowering investors across the globe. Explore our journey, achievements, and dedication to innovative, long-term investment solutions for a brighter financial future." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='about-page'>
        <Header />
        <Banner />
        <Tabs />
        {/* <Slider /> */}
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutPage;
