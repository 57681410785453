import React from "react";
import styles from './Passive.module.scss';
import { Trans, useTranslation } from "react-i18next";

import icon1 from '../../../assets/images/homepage/icon_1.svg';
import icon2 from '../../../assets/images/homepage/icon_2.svg';
import icon3 from '../../../assets/images/homepage/icon_3.svg';

const Passive = () => {
  const { t } = useTranslation();
  const icons = [icon1, icon2, icon3];
  const list = t('trading.six.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} ${styles.colored}`}><Trans>{t('trading.six.title1')}</Trans></div>
        <div className={styles.title}><Trans>{t('trading.six.title2')}</Trans></div>

        <div className={styles.list}>
          {list.map((item, index) => (
            <div className={styles.listItem} key={index}>
              <img src={icons[index]} alt="" className={styles.listItemIcon}  />
              <div className={styles.listItemTitle}>{item.title}</div>
              <div className={styles.listItemText}>{item.text}</div>
            </div>

          ))}
        </div>
      </div>
    </section>
  );
};

export default Passive;