import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/AccountsPage/Banner/Banner';
import Tabs from '../components/AccountsPage/Tabs/Tabs';
import Accordion from '../components/GlodalComponents/AccordionBtn/AccordionBtn';
import Steps from '../components/GlodalComponents/Steps/Steps';

function AccountsPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>FMILX · Account types</title>
        <meta name="title" content="FMILX account types: tailored solutions for every investor" />
        <meta property="og:title" content="FMILX account types: tailored solutions for every investor" />
        <meta property="twitter:title" content="FMILX account types: tailored solutions for every investor" />

        <meta name="description" content="Discover FMILX’s range of account types, each crafted to meet the diverse needs and goals of investors. Our customized account options offer personalized solutions to optimize your experience and help you achieve your financial objectives with FMILX." />
        <meta property="og:description" content="Discover FMILX’s range of account types, each crafted to meet the diverse needs and goals of investors. Our customized account options offer personalized solutions to optimize your experience and help you achieve your financial objectives with FMILX." />
        <meta property="twitter:description" content="Discover FMILX’s range of account types, each crafted to meet the diverse needs and goals of investors. Our customized account options offer personalized solutions to optimize your experience and help you achieve your financial objectives with FMILX." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='account-page'>
        <Header />
        <Banner />
        <Tabs />
        <Accordion blockTitle={'acct.third.title'} title={'acct.third.faqs'} withBtn />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccountsPage;
