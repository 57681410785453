import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from '../../GlodalComponents/Button/Button';
import styles from './Tabs.module.scss';

import icon from '../../../assets/images/check.svg';
import image from '../../../assets/images/about/image2.webp';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const tabContent = t('about.second.tabs.titles', { returnObjects: true });
  const list = t('about.second.tabs-3.list', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.tabButtons}>
          {tabContent.map((tab, index) => (
            <Fade left delay={index * 100 + 1} key={index}>
              <button
                key={index}
                className={index === activeTab ? styles.tabActive : ''}
                onClick={() => handleTabClick(index)}
              >
                {t(`${tab.title}`)}
              </button>
            </Fade>
          ))}
        </div>

        <div className={styles.tabsContent}>

          <div className={`${styles.tabItem} ${0 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemColumn}>
              <img src={image} alt='' />
              <div className=''>
                <div className={styles.tabItemTitle}>{t('about.second.tabs-1.title')}</div>
                <div className={styles.tabItemSubtitle}>{t('about.second.tabs-1.subtitle')}</div>
                <div className={styles.tabItemText}><Trans>{t('about.second.text')}</Trans></div>
                <div className='mt-btn'><Button bold /></div>
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${1 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('about.second.tabs-2.title')}</div>
            <div className={styles.tabItemSubtitle}>{t('about.second.tabs-2.subtitle')}</div>
            <div className={styles.tabItemText}><Trans>{t('about.second.text')}</Trans></div>
            <div className='mt-btn'><Button bold /></div>
          </div>

          <div className={`${styles.tabItem} ${2 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemTitle}>{t('about.second.tabs-3.title')}</div>
            <div className={styles.tabItemSubtitle}>{t('about.second.tabs-3.text')}</div>
            <div className={styles.tabItemText}><Trans>{t('about.second.text')}</Trans></div>
            
            <div className={styles.tabList}>
              {list.map((item, index) => (
                <div className={styles.tabListItem} key={index}>
                  <img src={icon} alt='' className={styles.tabListItemIcon} />
                  <div className={styles.tabListItemContent}>
                    <Trans>{item.title}</Trans>
                  </div>
                </div>
              ))}
            </div>
            
          </div>


        </div>
      </div>
    </div>
  );
};

export default Tabs;