import React from "react";
import styles from './TextAcc.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Accordion from '../../GlodalComponents/Accordion/Accordion';
import Button from "../../GlodalComponents/Button/Button";

const TextAcc = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={styles.title}><Trans>{t('trading.five.title')}</Trans></div>
          <div className={styles.text}><Trans>{t('trading.five.text')}</Trans></div>
          <div className={styles.text}><Trans>{t('trading.five.text1')}</Trans></div>
          <div className="mt-btn"><Button bold /></div>
        </div>
        <div className={styles.item}>
          <Accordion title={'trading.five.acc'} />
        </div>
      </div>
    </section>
  );
};

export default TextAcc;