import React from "react";
import styles from './Range.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import icon from '../../../assets/images/check.svg';

const Range = () => {
  const { t } = useTranslation();
  const list = t('support.third.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} ${styles.colored}`}><Trans>{t('support.third.title1')}</Trans></div>
          <div className={styles.title}><Trans>{t('support.third.title2')}</Trans></div>
          <div className={styles.text}><Trans>{t('support.third.text1')}</Trans></div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <img src={icon} alt="" className={styles.listItemIcon}  />
                <div className={styles.listItemTitle}>{item.title}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.text}><Trans>{t('support.third.text2')}</Trans></div>
          <div className="mt-btn"><Button bold /></div>
        </div>
      </div>
    </section>
  );
};

export default Range;