import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
// import Marquee from "react-fast-marquee";

import m1 from '../../../assets/images/homepage/marquee/m-1.webp';
import m2 from '../../../assets/images/homepage/marquee/m-2.webp';
import m3 from '../../../assets/images/homepage/marquee/m-3.webp';
import m4 from '../../../assets/images/homepage/marquee/m-4.webp';
import m5 from '../../../assets/images/homepage/marquee/m-5.webp';
import m6 from '../../../assets/images/homepage/marquee/m-6.webp';
import m7 from '../../../assets/images/homepage/marquee/m-7.webp';
import m8 from '../../../assets/images/homepage/marquee/m-8.webp';
import m9 from '../../../assets/images/homepage/marquee/m-9.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container first-d`}>
        <div className={styles.left}>
          <h1 className={styles.title}><Trans>{t('main.first.title-0')}</Trans></h1>
          <h5 className={styles.title}><Trans>{t('main.first.title-1')}</Trans></h5>
          <div className={`mob_only ${styles.centered}`}>
            <Button bold />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.MarqueeWrapper}>
            <div className={styles.MarqueeBlock}>
              <div className={styles.MarqueeInner}>
                    <div className={styles.MarqueeItem}><img src={m1} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m2} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m3} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m1} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m2} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m3} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m1} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m2} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m3} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m1} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m2} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m3} alt="" className={styles.marqueeItemImg} /></div>
              </div>
            </div>
          </div>
          <div className={styles.MarqueeWrapper}>
            <div className={styles.MarqueeBlock}>
              <div className={styles.MarqueeInner}>
                    <div className={styles.MarqueeItem}><img src={m4} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m5} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m6} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m4} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m5} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m6} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m4} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m5} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m6} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m4} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m5} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m6} alt="" className={styles.marqueeItemImg} /></div>
              </div>
            </div>
          </div>
          <div className={styles.MarqueeWrapper}>
            <div className={styles.MarqueeBlock}>
              <div className={styles.MarqueeInner}>
                    <div className={styles.MarqueeItem}><img src={m7} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m8} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m9} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m7} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m8} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m9} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m7} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m8} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m9} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m7} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m8} alt="" className={styles.marqueeItemImg} /></div>
                    <div className={styles.MarqueeItem}><img src={m9} alt="" className={styles.marqueeItemImg} /></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.left}>
          <div className={`${styles.text} ${styles.mart}`}><Trans>{t('main.first.text1')}</Trans></div>
          <div className={styles.text}><Trans>{t('main.first.text2')}</Trans></div>
          <div className="mt-btn desk_only"><Button bold /></div>
        </div>
      </div>
    </section>
  );
};

export default Banner;