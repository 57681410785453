import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Reveal } from "react-reveal";

import image from '../../../assets/images/about/image1.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.item} ${styles.itemLeft}`}>
          <h1 className={`${styles.title} ${styles.colored}`}><Trans>{t('about.first.title')}</Trans></h1>
          <div className={styles.text}><Trans>{t('about.first.text')}</Trans></div>
          <div className="mt-btn">
            <Button bold />
          </div>
        </div>
        <Reveal effect="zoomIn">
          <div className={styles.item}>
            <img src={image} alt="" />
          </div>
        </Reveal>
      </div>
    </section>
  );
};

export default Banner;