import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import Button from '../Button/Button';
import arrow from '../../../assets/images/icon-minus.svg';
import styles from './AccordionBtn.module.scss';

const Accordion = ({blockTitle, title, withBtn = false}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items = t(`${title}`, { returnObjects: true });

  const renderedItems = items.map((item, index) => {

    return (
      <div key={index} className={`${styles.item} ${index === activeIndex ? styles.itemActive : ''}`} >
        <div 
          className={`${styles.itemTitle} ${index === activeIndex ? styles.titleActive : ''}`} 
          onClick={() => onTitleClick(index)}
        >
          {item.title}
          <div className={styles.icon}>
            <img src={arrow} alt='' />
          </div>
        </div>
        <div className={`${styles.content} ${index === activeIndex ? styles.contentActive : ''}`}>
          <div className={styles.text}>
            <Trans>
              {item.content}
            </Trans>
          </div>
          {withBtn && 
            <div className={styles.btn}>
              <Button bold />
            </div>
          }
        </div>
      </div>
    );
  });

  return <div className={styles.accordion}>
    <div className='container'>
      <div className={styles.title}>{t(`${blockTitle}`)}</div>
      {renderedItems}
    </div>
  </div>;
};

export default Accordion;