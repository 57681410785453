import React from "react";
import styles from './ListMarquue.module.scss';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const ListMarquue = () => {
  const { t } = useTranslation();
  const list = t('trading.second.list1', { returnObjects: true });
  const list1 = t('trading.second.list2', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <Marquee direction="right" autoFill>
            <div className={styles.row}>
              {list.map((item, index) => (
                <div className={`${styles.rowItem} ${styles[`block-${index}`]}`} key={index}>{item.title}</div>
              ))}
              {list.map((item, index) => (
                <div className={`${styles.rowItem} ${styles[`block-${index}`]}`} key={index}>{item.title}</div>
              ))}
            </div>
          </Marquee>
        </div>
        <div className={styles.item}>
          <Marquee autoFill>
            <div className={styles.row}>
              {list1.map((item, index) => (
                <div className={`${styles.rowItem} ${styles[`block-${index}`]}`} key={index}>{item.title}</div>
              ))}
              {list1.map((item, index) => (
                <div className={`${styles.rowItem} ${styles[`block-${index}`]}`} key={index}>{item.title}</div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default ListMarquue;